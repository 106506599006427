.driver-schedule-list {
    color: $textcolor;
    .driver-schedule-header {
        padding-left: 70px;
        .driver-info-section {
            display: flex;
            height: 50px;
            flex-direction: row;
            align-items: center;
            align-content: space-between;
            .driver-info {
                display: contents;
                .driver-color-row {
                    height: 46px;
                    width: 10px;
                }
                .driver-nr {
                    background-color: $white;
                    height: 46px;
                    width: 76px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: $font-weight-regular;
                    font-size: 16px;
                }
            }
            .driver-location {
                font-size: 16px;
                font-weight: $font-weight-bold;
                margin-left: 20px;
            }
        }
        .driver-name-row {
            margin-top: 20px;
            margin-bottom: 10px;
            .driver-name {
                font-size: 26px;
                font-weight: $font-weight-bold;
            }
        }
    }
    .week-row-to-schedule {
        margin-top: 50px;
        .week-nr-col {
            float: left;
            width: 70px;
            height: 350px;
            .note-date {
                font-size: 21px;
                font-weight: $font-weight-bold;
            }
        }
        .driver-week-list {
            .week-col {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 5px 0;
                .day-name {
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    width: 40px;
                }
                .select-warehouse {
                    width: 280px;
                    .general-width-to-inputs {
                        width: 98%;
                        .simple-select-driver-schedule {
                            @include input-styles();
                            padding:7px 6px 0px 10px;
                            margin: 5px;
                        }
                    }
                    .general-width-to-inputs.select-warehouse-width {
                        max-width: 200px;
                        height: 40px;
                    }
                }
            }
            .circle-list {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $textcolor;
                margin: 1px 0;
                display: block;
            }
        }
    }
    &:last-child {
        padding-right: 50px;
    }
}

.start-end-date-send-button {
    align-items: center;
    display: flex;
}

.select-week-dropdown {
    width: 200px;
    margin-top: 20px;
    margin-right: 40px
}

.week-select-dropdown {
    @include input-styles();
    padding: 0px 1px 4px 0px;
    width: 100%;
    margin-top: 25px!important;
}

.right-button-select-section {
    position: absolute;
    right: 60px;
    top: 30px;
    .select-week-input {
        @include input-styles();
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.06);
        }
    }
}

.send_nexd_day_button {
    margin-left: 20px!important;
    padding: 10px 10px!important;
    margin-top: 22px!important;
    margin-left: 100px!important;
}

.scheduleButton {
    padding: 12px 15px;
    width: 85px;
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 40px;
  }
