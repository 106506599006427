.pr-container {
    display: flex;
    background-color: $background;
    .data-container {
        width: 88%;
        background-color: $background;
        padding: 20px;
        .data-container-inner {
            position: relative;
        }
        .dashboard-title {
            margin-top: 10px;
            margin-bottom: 2%;
            .primary-title {
               @include primary-title();
            }
            .secondary-title {
                @include secondary-title();
            }
        }
        .copyright-section {
            text-align: center;
            margin-top: 20%;
            a.copyright-link {
                color: $textcolor;
                @include transition();
                &:hover {
                    text-decoration: none;
                    color: $generalblue;
                }
            }
        }
    }
}

.loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
