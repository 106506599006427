.default-table-container {
    width: 95%;
    .default-table-list {
        max-height: 400px;
        .colorCode-cell {
            min-width: 30px;
            text-align: left;
        }
        .nr-cell {
            min-width: 200px;
            text-align: left;
        }
        .nr-cell-inactive {
            min-width: 130px;
            text-align: left;
        }
        .driver-name-cell {
            min-width: 400px;
            text-align: left;
        }
        .driver-name-cell-inactive {
            min-width: 300px;
            text-align: left;
        }
        .location-cell, .phone-cell {
            min-width: 150px;
            text-align: left;
        }
        .id-inactive-cell {
            min-width: 250px;
            text-align: right;
        }
        .edit-cell {
            min-width: 30px;
            text-align: right;
        }
        .category-cell, .grade-cell {
            min-width: 150px;
            text-align: center;
        }
        .table-list-head {
            background-color: $background;
            text-transform: uppercase;
            .table-list-head-cell {
                background-color: $background;
                color: $textcolor;
                border: 0;
                font-size: 12px;
                font-weight: $font-weight-bold;
                box-shadow: 0 2px 0px rgba(0, 0, 0, 0.02);
            }
        }
    }
    .default-table-body {
        .table-fullwidth-row {
            @include table-tr-style();
            background-color: $white;
            @include transition();
            .columns-grid {
                color: $textcolor;
                border: 6px;
                font-size: 16px;
                padding-top: 8px;
                .grade-circle-cell {
                    .grade-circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        display: inline-table;
                    }
                }
            }
            .driver-name {
                font-weight: $font-weight-bold;
            }
            .edit-drivers {
                font-size: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                .circle-list {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: $textcolor;
                    margin: 1px 0;
                    display: block;
                }
            }
            .edit-inactive-drivers {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: $font-weight-bold;
                a.link-accept {
                    color: $acceptbutton;
                    margin-right: 30px;
                }
                a.link-declined {
                    color: $declinebutton;
                    margin-right: 10px;
                }
            }
            .inactive-color-line {
                width: 10px;
                height: 46px;
                background-color: $textcolor;
                margin: 2px 0;
            }
            .color-line {
                width: 10px;
                height: 60px;
                margin: 0;
            }
        }
    }
    .table-pagination {
       @include table-pagination();
    }
}
.driver-inactive-registration-list {
    margin-top: 10%;

    .text {
        color: $placeholderInput;
    }
}

.queue-table-container {
    .text {
        color: $placeholderInput;
    }
}

#date-picker-inline1, #date-picker-inline2 {
    padding-bottom: 15px;
    padding-left: 10px;
}
